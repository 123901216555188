/* Creating Universal Styles */

body {
  margin: 0;
  background-color: var(--color-white) !important;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: var(--font-family-primary);
  color: var(--color-black);
}

h1.inverse,
h2.inverse,
h3.inverse,
h4.inverse,
h5.inverse,
h6.inverse,
p.inverse {
  color: var(--color-white);
}

.btn {
  border-radius: 0;
}

.container {
  --max-width: 1200px;
  --padding: 1.5rem;

  width: min(var(--max-width), 100% - (var(--padding)*2));
  margin-inline: auto;
}

@media screen and (max-width: 650px) {
  .container {
    --max-width: 1200px;
    --padding: 1rem;

    width: min(var(--max-width), 100% - (var(--padding)*2));
    margin-inline: auto;
  }
}