.social-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; 
    z-index: 1000;
    background-color: var(--color-black);
}

.social-text {
    padding-left: 0.5rem;
    margin-bottom: 0;
}

.social-logo {
    width: 1.5rem;
}

@media screen and (max-width:1200px) {
    .social-header .nav-link {
        padding: 0.5rem 0rem 0.5rem 2rem !important;
    }

    .desktop-only {
        display: none;
    }
}