/* Import Assets and Typeface */

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --font-family-primary:  "Jost", sans-serif;

  --color-white: #FCFCFC;
  --color-gray: #EBEBEA;
  --color-red: #A01D35;
  --color-dark-red: #6e1424;
  --color-black: #181818;
  
}