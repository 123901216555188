.review-container {
    background-color: var(--color-white);
    box-shadow: 0 4px 10px 10px rgba(0, 0, 0, 0.1); 
}

.review-row {
    background-color: var(--color-gray);
}

span {
    font-family: var(--font-family-primary);
}

a {
    color: inherit; 
    text-decoration: none; 
    cursor: pointer; 
}

.show-btn {
    color: darkgray;
    font-style: italic;
}