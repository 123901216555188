.shadow {
    box-shadow: 0 4px 10px 10px rgba(0, 0, 0, 0.1); 
}

.contact-info {
    background-color: rgba(0,0,0,1);
}

.accordion {
    --bs-accordion-btn-focus-box-shadow: 0 0 0 2px rgba(129, 4, 27, 0.473);
    --bs-accordion-active-bg: none;
    --bs-accordion-border-color: lightgray;
    --bs-border-radius: 0;
}