.contact-form {
    padding: 4rem 0;
}

.social-icon {
    width: 2rem;
}

.social-name {
    margin: 0;
    padding-left: 1rem;
}

textarea {
    resize: none;
}

.contact-info a {
    text-decoration: none;
}

.send-btn {
    border-radius: 0;
    background-color: var(--color-red);
    border: none;
}

.send-btn:hover {
    background-color: var(--color-dark-red) !important;
}

.form-control {
    font-family: var(--font-family-secondary);
    background-color: var(--color-light);
    border-color: var(--color-gray);
    border-radius: 0;
}

.form-control::placeholder {
    font-family: var(--font-family);
}

.form-control:focus {
    background-color: var(--color-white);
    border-color: var(--color-dark-red);
    box-shadow: 0 0 0 2px rgba(129, 4, 27, 0.473);
}

/* honey-pot */
.not-today {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}