.bg {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 40rem;
    max-height: 60rem;
}

.hero-content {
    padding: 10rem 0rem;
}

.hero-paragraph {
    margin-right: 40dvw;
    min-width: 20rem;
}

.free-estimate-btn {
    width: 18rem;
    border-radius: 0;
    background-color: var(--color-red);
    border: none;
}

.free-estimate-btn:hover {
    background-color: var(--color-dark-red) !important;
}