.footer {
    background-color: var(--color-black);
}

.footer-padding {
    padding: 4rem 0;
}

.copyright {
    color: var(--color-white);
    font-size: x-small;
}

