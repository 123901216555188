.image-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.service-img {
    width: calc(100% ); 
    height: auto;
    aspect-ratio: 3 / 2; 
    margin: 10px; 
    object-fit: cover; 

}

.image-container:before {
    content: "";
    position: absolute;
    top: -20px; 
    bottom: -20px; 
    left: 40%; 
    right: -55%; 
    background: linear-gradient(to right, var(--color-gray), var(--color-white)); 
    z-index: -1;
}

.inverse:before {
    left: -55%;
    right: 40%; 
    background: linear-gradient(to left, var(--color-gray), var(--color-white)); 
}